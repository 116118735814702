// import * as $ from 'jquery/dist/jquery.slim'
import{ CoreError } from '../Core'
import { AppType, GLOBAL_AppPlatform, Metrics, Page, Platform, TriggerPoint } from '../Metrics'


declare var GLOBAL_BaseApiURL_Direct:string


// TODO(arch): is there a better/standard way to initialize this? (ie: encapsulate within class)
export function initAppsWidget( page:Page ){
	// TODO: handle both platform buttons
	let iOSButton:JQuery<HTMLElement> = $('#__appsWidget > .content > a')
	iOSButton.on( 'click', async () => {
		if( GLOBAL_AppPlatform == Platform.iOS ) // TEMP: workaround for Amplitude bug where filters are not respected when synching
			await Metrics.logNAV_launchApp( AppType.Permanent, page, TriggerPoint.SecondaryCTA )

		// navigate
		let href:string = iOSButton.attr( 'data-href' )
//		console.debug( `CTA HREF: ${href}` )
		await Metrics.flush() // NOTE: ensure metrics are pushed before navigating away
		window.location.href = href
	})
	
	$('#__appsWidget #androidPreRegisterForm').on( 'submit', async e => {
		// prevent form submission (NOTE: we handle in AJAX instead)
		e.preventDefault()
		e.stopPropagation()
		
		// validate
		const countryCodeTextField:JQuery<HTMLElement> = $('#__appsWidget #countryCodeTextField')
		let countryCode:string|number|string[]|undefined = countryCodeTextField.val()
		if( !countryCode ){
			countryCode = '+1'
		} else if( countryCode instanceof String ){
			countryCode = countryCode.trim()
		}
		const phoneNumberTextField:JQuery<HTMLElement> = $('#__appsWidget #phoneNumberTextField')
		let phoneNumber:string|number|string[]|undefined = phoneNumberTextField.val()
		if( phoneNumber instanceof String ){
			phoneNumber = phoneNumber.trim()
		}
		const errorLabel:JQuery<HTMLElement> = $('__appsWidget #errorLabel')
		if( !phoneNumber ){
			phoneNumberTextField.addClass( 'is-invalid' )
			phoneNumberTextField.addClass( 'was-validated' )
			errorLabel.text( "Please enter your phone number" )
			errorLabel.show() // NOTE: needed because error label is not a sibling of the input control
			phoneNumberTextField.trigger( 'focus' )
			return
		// TODO: validate value (can use DOM's 'checkValidity()' method – REF: https://stackoverflow.com/a/39593175/24177)
		// } else if( phoneNumberTextField ){
		//	
		} else {
			phoneNumberTextField.removeClass( 'is-invalid' )
			phoneNumberTextField.removeClass( 'was-validated' )
			errorLabel.hide()
		}
		
		// submit
		let preRegisterButton = $('#__appsWidget #registerButton')
		preRegisterButton.empty()
		preRegisterButton.append('<div class=\"async-indicator\"></div>' )
		errorLabel.hide()
		function showError( message?:string ){
			phoneNumberTextField.addClass( 'is-invalid' )
			phoneNumberTextField.trigger( 'focus' )
			errorLabel.text( message ?? "Something went wrong. Please try again later." )
			errorLabel.show() // NOTE: needed because error label is not a sibling of the input control
			preRegisterButton.empty()
			preRegisterButton.text( 'Pre-register' )
		}
		try {
			const response:Response = await fetch( `${GLOBAL_BaseApiURL_Direct}/account/subscribers`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					PhoneNumber: `${countryCode}${phoneNumber}`,
					Platform: 'Android',
				}),
			})
			if( !response.ok ){
				const statusCode:number = response.status
				if( 400 <= statusCode && statusCode < 500 ){
					const errorJSON:CoreError = await response.json()
					switch( errorJSON.ErrorCode ){
					case "InvalidPhoneNumber":
						console.log( `Could not pre-register ${countryCode}${phoneNumber}. Invalid phone number.` )
						showError( "Please enter a valid phone number" )
						break
						
					default:
						console.log( `Could not pre-register ${countryCode}${phoneNumber}. Status code: ${statusCode}` )
						showError()
					}
				} else {
					console.log( `Could not pre-register ${countryCode}${phoneNumber}. Status code: ${statusCode}` )
					showError()
				}
				return
			}

			preRegisterButton.text( 'Registered' )
			$('#__appsWidget .register-container > .unregistered').hide()
			$('#__appsWidget .register-container > .registered').show()
			console.log( `Pre-registered ${countryCode}${phoneNumber} successfully` )
			
		} catch( x ){
			console.log( `Could not pre-register ${countryCode}${phoneNumber}. ERROR: ${x.message}` )
			showError()
		}
	})
}
